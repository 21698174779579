<template>
    <div class="uk-container uk-container-expand uk-margin-top card-scrollable">

        <div class="uk-flex uk-margin uk-width-1-4">
            <button class="uk-button uk-button-default uk-width-expand" style="background:white" type="button">{{filterDate}}</button>
                <div id="date-dropdown" uk-dropdown="mode: click;pos: right-top">
                    <ul class="uk-dropdown-grid uk-child-width-auto" uk-grid>
                        <div>
                            <datetime
                                    id="start_date"
                                    input-class="uk-button"
                                    v-model="meta.start_date"
                                    :max-datetime="new Date().toISOString()"
                                    placeholder="Start Date"
                                    @select="getInitPageData()"
                                ></datetime>
                        </div>
                        <template v-if="meta.start_date">
                            <div class="uk-text-center uk-margin-auto-vertical">-</div>
                            <div>
                                <datetime
                                        id="end_date"
                                        input-class="uk-button"
                                        v-model="meta.end_date"
                                        :min-datetime="meta.start_date"
                                        :max-datetime="new Date().toISOString()"
                                        placeholder="End Date"
                                    ></datetime>
                            </div>
                            <div>
                                <button class="uk-button uk-button-primary uk-width-auto" type="button" v-if="meta.start_date" @click="setDate()">Apply</button>
                            </div>
                        </template>
                    </ul>
                </div>
            <button class="uk-button-small uk-button-danger uk-width-auto" type="button" v-if="meta.start_date" @click="resetDate()"><span uk-icon="icon: close"></span></button>
        </div>

        <div class="uk-flex uk-flex-between uk-width-expand uk-margin">
            <div class="uk-flex uk-flex-between uk-width-1-4">
                <div class="uk-width-1-3">
                    <select class="uk-select" v-model="meta.search_by" @change="getInitPageData()">
                        <option :value="null" disabled>Search By</option>
                        <option value="feed">Konten Post</option>
                        <option value="author">Author</option>
                    </select>
                </div>
                <div class="uk-width-1-2">
                    <input class="uk-input" type="text" v-model="meta.search" :disabled="!meta.search_by" placeholder="Keyword" @keypress.enter="getInitPageData()">
                </div>
            </div>
            <div class="uk-flex uk-flex-bottom uk-width-auto uk-text-right uk-text-bold">
                Total: {{page_data.totalDocs}} Posts
            </div>
        </div>

        <div class="uk-card uk-card-default">
            <div class="uk-overflow-auto">
                <table class="uk-table uk-table-medium uk-table-striped">
                    <thead>
                        <tr class="uk-text-left">
                            <th class="uk-width-auto sortable" @click="changeSort('feed')">
                                Konten Post
                                <span uk-icon="triangle-up" v-if="meta.sort_by == 'feed' && meta.sort == 'asc'"></span>
                                <span uk-icon="triangle-down" v-if="meta.sort_by == 'feed' && meta.sort == 'desc'"></span>
                            </th>
                            <th class="uk-width-auto sortable" @click="changeSort('author')">
                                Author
                                <span uk-icon="triangle-up" v-if="meta.sort_by == 'author' && meta.sort == 'asc'"></span>
                                <span uk-icon="triangle-down" v-if="meta.sort_by == 'author' && meta.sort == 'desc'"></span>
                            </th>
                            <th class="uk-width-auto sortable" @click="changeSort('count_like')">
                                Likes
                                <span uk-icon="triangle-up" v-if="meta.sort_by == 'count_like' && meta.sort == 'asc'"></span>
                                <span uk-icon="triangle-down" v-if="meta.sort_by == 'count_like' && meta.sort == 'desc'"></span>
                            </th>
                            <th class="uk-width-auto sortable" @click="changeSort('count_comment')">
                                Comments
                                <span uk-icon="triangle-up" v-if="meta.sort_by == 'count_comment' && meta.sort == 'asc'"></span>
                                <span uk-icon="triangle-down" v-if="meta.sort_by == 'count_comment' && meta.sort == 'desc'"></span>
                            </th>
                            <th class="uk-width-auto sortable" @click="changeSort('count_click')">
                                Clicks
                                <span uk-icon="triangle-up" v-if="meta.sort_by == 'count_click' && meta.sort == 'asc'"></span>
                                <span uk-icon="triangle-down" v-if="meta.sort_by == 'count_click' && meta.sort == 'desc'"></span>
                            </th>
                            <th class="uk-width-auto uk-text-center">Action</th>
                        </tr>
                    </thead>
                    <loading-table v-if="isLoading" :colspan="6"/>
                    <tbody v-else-if="page_data.docs.length>0">
                        <tr v-for="(data, i) in page_data.docs" :key="i" class="uk-text-left">
                            <td>{{preview(data.feed)}}</td>
                            <td>{{preview(data.author)}}</td>
                            <td>{{data.count_like}}</td>
                            <td>{{data.count_comment}}</td>
                            <td>{{data.count_click}}</td>
                            <td class="uk-text-center">
                                <button class="uk-button uk-button-small uk-button-default" type="button" disabled>Actions</button>
                            </td>
                        </tr>
                    </tbody>
                    <empty-table v-else :colspan="6"/>
                </table>
            </div>
            <pagination
                :total-data="page_data.totalDocs"
                :change-limit="changeLimit"
                :change-page="changePage"
            />
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import EmptyTable from '@/components/globals/tables/EmptyTable';
import LoadingTable from '@/components/globals/tables/Loading';
import Pagination from '@/components/globals/Pagination';
import { Datetime } from 'vue-datetime';
import formatter from "@/utils/formatter";

export default {
    data(){
        return {
            meta: {
                search_by: null,
                search: '',
                start_date: null,
                end_date: null,
                sort_by: null,
                sort: 'desc',
                limit: 50,
                page: 1
            },
            page_data: {docs: [], totalDocs: 0},
            isLoading: true
        };
    },
    components: {
        EmptyTable,
        Datetime,
        Pagination,
        LoadingTable
    },
    watch: {
        meta() {
            this.getInitPageData();
        },
    },
    async mounted() {
        await this.getInitPageData();
    },
    computed: {
        filterDate(){
            if (!this.meta.start_date) {
                return 'Filter Tanggal';
            } else if (!this.meta.end_date) {
                return formatter.dateComplete(this.meta.start_date);
            } else {
                return formatter.dateComplete(this.meta.start_date) + ' - ' + formatter.dateComplete(this.meta.end_date);
            }
        }
    },
    methods: {
        ...mapActions({
            getFeedReport: 'report/getFeedReport',
        }),
        async getInitPageData(){
            this.isLoading = true;
            this.page_data = await this.getFeedReport(this.meta);
            this.isLoading = false;
        },
        changeLimit(e) {
            this.meta = {
                ...this.meta,
                limit: e.target.value
            };
        },
        changePage(value) {
            this.meta = {
                ...this.meta,
                page: value
            };
        },
        async setDate() {
            await this.getInitPageData();
            await window.UIkit.dropdown('#date-dropdown').hide();
        },
        async resetDate() {
            this.meta.start_date = null;
            this.meta.end_date = null;
            await this.getInitPageData();
        },
        preview(data) {
            if (data == null) {
                return '-';
            }
            if (data.length > 26) {
                return data.substring(0,26)+'...';
            } else {
                return data;
            }
        },
        async changeSort(sort_by) {
            if (this.meta.sort_by != sort_by) {
                this.meta.sort_by = sort_by;
            } else {
                this.meta.sort = this.meta.sort == 'desc' ?  'asc' : 'desc';
            }
            await this.getInitPageData();
        }
    },
};
</script>

<style scoped>
.sortable {
    cursor:pointer
}
</style>
